.inputField {
  @apply block w-full text-sm leading-normal p-3 font-normal border border-solid rounded-[10px] focus:ring-2 focus:ring-[dark/20] focus:ring-offset-2 transition-all duration-300;
}

.inputField:disabled {
  @apply bg-[#F9F9F7];
}

.react-datepicker__navigation {
  outline: none !important;
}
